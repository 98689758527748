import React from 'react'
import classes from './UserInfo.module.css'
import settingsIcon from '../../../../images/icons/settings.svg'
import { Link } from 'gatsby'
import Paper from '@material-ui/core/Paper'

export default props => {
  let daysLeftClasses = []

  switch (true) {
    case props.daysLeft >= 7:
      daysLeftClasses.push(classes.Green)
      break
    case props.daysLeft <= 6 && props.daysLeft > 1:
      daysLeftClasses.push(classes.Yellow)
      break
    case props.daysLeft === 1 || props.daysLeft >= 0:
      daysLeftClasses.push(classes.Red)
      break
    default:
      daysLeftClasses.push(classes.Hidden)
      break
  }

  return (
    <Paper elevation={3} className={classes.User}>
      <div className={classes.Settings}>
        <Link to="/pmp-preparation-edit-profile">
          <img
            src={settingsIcon}
            className={classes.SettingsIcon}
            alt="settings icon"
          />
        </Link>
      </div>
      <div>
        <center>
          <span className={classes.Heading}>USER INFO</span>
        </center>
      </div>
      <div className={classes.UserInfo}>
        <div className={classes.InfoTitle}>
          <span>Name:</span>
          <span>Email:</span>
          <span>Paid access:</span>
        </div>
        <div className={classes.InfoValue}>
          <span>{props.name ? props.name : 'Your name'}</span>
          <span>{props.email ? props.email : 'your.email@mail.com'}</span>
          <span>
            <span className={daysLeftClasses.join(' ')}>
              {props.daysLeft ? props.daysLeft : 0}
            </span>{' '}
            days left
          </span>
        </div>
      </div>
    </Paper>
  )
}
