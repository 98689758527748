import React from 'react'
import Layout from '../hoc/Layout/Layout'
import Profile from '../components/Pages/Profile/Profile'
import SEO from '../components/seo'

export default props => {
  const params = props.location.search

  let id = null

  if (params !== '') {
    id = params.split('=')

    if (id.length !== 0) id = id.pop()
  }

  return (
    <Layout>
      <SEO
        title="Profile | PMP Certification"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description={`Exam Questions to get PMP Certification. Unlimited Questions to Sharpen Your Skills with In-depth Explanations.`}
      ></SEO>
      <Profile session={id} />
    </Layout>
  )
}
