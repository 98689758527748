import React, { Component } from 'react'
import classes from './Profile.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'
import Alert from '@material-ui/lab/Alert'
import Helmet from 'react-helmet'
import axios from '../../../utils/axiosBackAPI'
import prices from '../../../utils/prices/prices'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Feature from './Feature/Feature'
import UserInfo from './UserInfo/UserInfo'
import Card from './Card/Card'
import Spinner from '../../UI/Spinner/Spinner'

class Profile extends Component {
  state = {
    daysToAdd: prices[Math.floor(prices.length / 2)].days,
    stripe: null,
    spinner: null,
    alert: null
  }

  handleOnChange = e => {
    this.setState({
      daysToAdd: Number(e.target.value)
    })
  }

  handleOnCheckOut = () => {
    this.setState({
      spinner: <Spinner />
    })

    const price = prices.filter(p => p.days === this.state.daysToAdd)[0].price

    const data = {
      days: this.state.daysToAdd,
      price: price,
      email: this.props.email
    }

    axios
      .post('/payment/session', data)
      .then(res => {
        if (res.data) {
          this.props.onReplenishSession(res.data, this.state.daysToAdd)

          this.setState({
            spinner: null
          })

          this.state.stripe
            .redirectToCheckout({
              sessionId: res.data
            })
            .then(result => {
              console.log(`STRIPE error`, result.error.message)

              const alert = (
                <Alert
                  severity="error"
                  onClose={() => {
                    this.props.onEraseReplenishPaidAccessMessage()

                    this.setState({ alert: null })
                  }}
                >
                  Unfortunately something went wrong, please try one more time.
                </Alert>
              )

              this.setState({
                alert: alert,
                spinner: null
              })
            })
        }
      })
      .catch(err => {
        console.log(`Back err`, err)

        const alert = (
          <Alert
            severity="error"
            onClose={() => {
              this.props.onEraseReplenishPaidAccessMessage()

              this.setState({ alert: null })
            }}
          >
            Unfortunately something went wrong, please try one more time.
          </Alert>
        )

        this.setState({
          alert: alert,
          spinner: null
        })
      })
  }

  componentDidMount() {
    if (this.props.session) {
      if (!this.props.userId) {
        // FIXME
        const t = setInterval(() => {
          if (this.props.userId) {
            clearInterval(t)

            if (this.props.replenishSession) {
              if (this.props.session === this.props.replenishSession.id) {
                this.props.onReplenishPaidAccess(
                  this.props.userId,
                  this.props.token,
                  this.props.replenishSession.days,
                  this.props.paidAccessUntil
                )
              }
            }
          }
        }, 100)
      }

      if (this.props.isReplenish) {
        let alert = null
        if (this.props.replenishSuccess) {
          alert = (
            <Alert
              severity="success"
              onClose={() => {
                this.props.onEraseReplenishPaidAccessMessage()
              }}
            >
              Congrats!!! You successfully replenished paid access.
            </Alert>
          )
        } else {
          alert = (
            <Alert
              severity="error"
              onClose={() => {
                this.props.onEraseReplenishPaidAccessMessage()

                this.setState({ alert: null })
              }}
            >
              Unfortunately something went wrong, please try one more time.
            </Alert>
          )
        }

        this.setState({ alert: alert })
      }
    }

    const int = setInterval(() => {
      if (window.Stripe) {
        clearInterval(int)

        const stripe = window.Stripe(
          'pk_live_aWBng0nu6x9l4bEaiUQNBtpa00u5j22UC5'
        )

        this.setState({
          stripe: stripe
        })
      }
    }, 10)

    if (!this.props.isFetched && this.props.userId && this.props.token) {
      this.props.onResultsFetch(this.props.userId, this.props.token)
    }
  }

  handleCardClicked = days => {
    this.setState({
      daysToAdd: days
    })
  }

  render() {
    const now = Date.now()
    const day = 24 * 60 * 60 * 1000
    const daysLeft =
      this.props.paidAccessUntil < now
        ? 0
        : Math.ceil((this.props.paidAccessUntil - now) / day)

    return (
      <div className={classes.Container}>
        <Helmet>
          {typeof window !== `undefined` ? (
            window.Stripe ? null : (
              <script
                id="stripe-js"
                src="https://js.stripe.com/v3/"
                async
              ></script>
            )
          ) : null}
        </Helmet>
        <center>
          <h1 className={classes.Heading}>PROFILE</h1>
        </center>
        {this.state.alert}
        <UserInfo
          name={this.props.name}
          email={this.props.email}
          daysLeft={this.props.paidAccessUntil ? daysLeft : null}
        />
        <Paper elevation={3}>
          <div className={classes.Replenish}>
            <div>
              <center>
                <span className={classes.Heading}>REPLENISH PAID ACCESS</span>
              </center>
            </div>
            <div className={classes.Features}>
              <Feature text="Unlimited questions for practice" />
              <Feature text="Exam Simulator running at the pace of actual exam" />
              <Feature text="Be able to lock particular field" />
              <Feature text="Get access to the statistics and performance chart" />
            </div>
            <div className={classes.Cards}>
              {prices.map(price => {
                return (
                  <Card
                    key={price.days}
                    days={price.days}
                    price={price.price}
                    click={this.handleCardClicked}
                    selected={this.state.daysToAdd === price.days}
                    mostPopular={
                      prices[Math.floor(prices.length / 2)].days === price.days
                    }
                    bestSaving={prices[prices.length - 1].days === price.days}
                  />
                )
              })}
            </div>
            {this.state.spinner}
            <div className={classes.Button}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#5fba7d', color: '1C132A' }}
                onClick={this.handleOnCheckOut}
              >
                <strong>&nbsp;Proceed to Checkout&nbsp;</strong>
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    email: state.user.email,
    name: state.user.name,
    paidAccessUntil: state.user.paidAccessUntil,
    replenishSession: state.user.replenishSession,
    isReplenish: state.user.replenishPaidAccessSuccess !== null,
    replenishSuccess: state.user.replenishPaidAccessSuccess,
    isFetched: state.statistics.isFetched
  }
}

const mapActionToProps = dispatch => {
  return {
    onReplenishPaidAccess: (userId, token, days, paidAccessUntil) =>
      dispatch(
        actions.userReplenishPaidAccess(userId, token, days, paidAccessUntil)
      ),
    onEraseReplenishPaidAccessMessage: () =>
      dispatch(actions.userReplenishPaidAccessEraseMessage()),
    onReplenishSession: (id, days) =>
      dispatch(actions.userReplenishSession(id, days)),
    onResultsFetch: (userId, token) =>
      dispatch(actions.resultFetch(userId, token))
  }
}

export default connect(mapStateToProps, mapActionToProps)(Profile)
