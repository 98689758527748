import React from 'react'
import classes from './Card.module.css'
import Paper from '@material-ui/core/Paper'
import prices from '../../../../utils/prices/prices'

export default props => {
  let cl = [classes.Card]

  if (props.selected) cl.push(classes.Selected)

  let mostPopular = null

  if (props.mostPopular) {
    mostPopular = (
      <div className={classes.Popular}>
        <span>Most Popular</span>
      </div>
    )
  }

  let bestSaving = null

  if (props.bestSaving) {
    bestSaving = (
      <div className={classes.BestSaving}>
        <span>Best Saving</span>
      </div>
    )
  }

  return (
    <Paper
      className={cl.join(' ')}
      elevation={3}
      onClick={() => {
        props.click(props.days)
      }}
    >
      <div className={classes.Days}>
        <span>
          <span className={classes.Day}>{props.days}</span>{' '}
          {props.days === 1 ? 'day' : 'days'}
        </span>
      </div>
      <div className={classes.Price}>
        <span>
          <span className={classes.Dollar}>$</span>
          <span className={classes.Amount}>{Math.floor(props.price)}</span>
          <span className={classes.Cents}>.99</span>
        </span>
      </div>
      <div className={classes.PerDay}>
        <span>
          <span className={classes.AmountPerDay}>
            ${Number(props.price / props.days).toFixed(2)}
          </span>
          <span className={classes.PerDayText}>/day</span>
        </span>
      </div>
      <div className={classes.Saving}>
        <span>
          <span className={classes.Percent}>
            {Number(
              100 - (100 * props.price) / props.days / prices[0].price
            ).toFixed(1)}
            %
          </span>
          <span className={classes.SavingText}> saving</span>
        </span>
      </div>
      {mostPopular}
      {bestSaving}
    </Paper>
  )
}
